/*====================
    Breadcrump Area 
======================*/

.breadcrumb-frame {
    padding: 100px;

    @media #{$sm-layout} {
        padding: 0;
        height: 70px;
    }

    @media (max-width: 575px) {
        height: 50px;
        margin-bottom: 50px;
    }
}

.breadcrumb-inner {
    background: linear-gradient(to right, #BF953F, #FCF6BA, #c09537, #FBF5B7, #AA771C);
    margin-top: 250px;
    margin-bottom: -260px;
    text-align: center;
    padding: 25px 150px;
    padding-top: 30px;
    padding-bottom: 25px;
    opacity: 0.95;
    box-shadow: 0 15px 30px 0 rgba(77, 77, 77, 0.14);

    @media #{$lg-layout} {
        padding: 25px 100px;
    }

    @media #{$md-layout} {
        padding: 25px 50px;
    }

    @media #{$sm-layout} {
        padding: 25px 50px;

    }

    @media (max-width: 575px) {
        margin-top: 200px;
        margin-bottom: 100px !important;
    }

    p {
        font-size: var(--font-size-md);
        margin-bottom: 0;
        color: black;

        @media #{$lg-layout} {
            font-size: 16px!important;
        }

        @media #{$md-layout} {
            font-size: 16px!important;
        }

        @media #{$sm-layout} {
            font-size: 16px!important;
        }

        @media (max-width: 575px) {
            font-size: 16px !important;
        }
    }

    h2 {
        &.title {
            color: var(--color-primary);
            color: #fff;
            margin-bottom: 5px;
            font-size: var(--font-size-h1);
            font-weight: 500;

            @media #{$lg-layout} {
                font-size: 30px;
            }

            @media #{$md-layout} {
                font-size: 26px;
            }

            @media #{$sm-layout} {
                font-size: 26px;
            }

            @media (max-width: 575px) {
                font-size: 20px;
            }
        }
    }

    ul {
        &.page-list {
            @extend %liststyle;

            li {
                display: inline-block;
                color: var(--color-primary);
                padding: 0 12px;
                position: relative;
                font-size: 14px;

                &::after {
                    position: absolute;
                    content: "";
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: #fff;
                    width: 5px;
                    height: 5px;
                    //border-radius: 100%;
                    opacity: 0.5;
                    right: -3px;
                }

                a {
                    color: var(--color-black);
                    transition: 0.5s;

                    &:hover {
                        color: var(--color-secondary);
                    }
                }

                &.rn-breadcrumb-item {
                    &.active {
                        color: var(--color-primary);

                        &::after {
                            display: none;
                        }
                    }
                }

            }
        }
    }
}



.breadcrumb-area {
    position: relative;
    z-index: 1;

    &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        z-index: -1;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='var(--color-primary)', endColorstr='var(--color-secondary)', GradientType=1);
        top: 0;
        left: 0;
        opacity: 0.7;
    }
}

.backto-top {
    >div {
        z-index: 999;
        width: 50px;
        height: 50px;
        line-height: 46px;
        //border-radius: 50%;
        background-color: #ffffff;
        text-align: center;
        overflow: hidden;
        z-index: 999 !important;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    }

    svg {
        font-size: 14px !important;
        color: #222222;
        width: 27px;
        height: 27px;
    }
}