.FAQ-section{
    padding-left: 15px;
    padding-right: 15px;
}

.faq-container {
    // font-size: 12px;
    border-bottom: 1px solid var(--Lightgrayishblue);
    // min-height: 50px;
    transition: all 0.5s ease-in-out;
}


.question-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    border-bottom: 1px solid black;
}

.question-container.active {
    
    border-bottom: 0px;
    // margin-bottom: -20px;
}

.question {
    font-weight: 400;
    cursor: pointer;
    color: rgb(43, 43, 43);
    margin-bottom: 20px;
    
}

.question:hover {
    color: var(--color-primary);
}

.question.active {
    font-weight: 600;
    color: var(--color-primary);
}

.answer {
    display: none;
}

.answer-display {
    padding-left: 20px;
    display: block;
    border-bottom: 1px solid black;
    padding-bottom: 30px;
    color: black; 
}

.arrow {
    cursor: pointer;
    transition: 0.5s ease-in-out transform;
    width: 15px;
    margin-top: -15px;
}

.arrow-rotate {
    transform: rotate(180deg);
    transition: 0.5s ease-in-out transform;
    width: 15px;
    margin-top: -15px;
}

