/*====================
    gold-api-area
======================*/

.gold-api-area {
    padding: 0 30px;

    h1 {
        font-size: 36px;
        font-weight: 500;
        color: rgb(255, 255, 255);

        @media (max-width: 575px) {
            font-size: 26px;
        }
    }

    h3 {
        font-size: 36px;
        font-weight: 400;
    }

    p {
        color: rgb(255, 255, 255);
    }



    .title {
        .row {

            .col-lg-6,
            .col-md-6,
            .col-sm-6 {
                align-content: center;
            }

            h1 {
                font-size: 40px;
                font-weight: 500;
                color: rgb(255, 255, 255);

                @media #{$lg-layout} {
                    font-size: 36px;
                }

                @media #{$md-layout} {
                    font-size: 34px;
                }

                @media #{$sm-layout} {
                    font-size: 42px;
                }

                @media (max-width: 575px) {
                    font-size: 26px;
                }
            }

            p {
                font-size: 26px;

                @media #{$lg-layout} {
                    font-size: 24px;
                }

                @media #{$md-layout} {
                    font-size: 24px;
                }

                @media #{$sm-layout} {
                    font-size: 22px !important;
                }

                @media (max-width: 575px) {
                    font-size: 18px !important;
                }
            }
        }


    }

    .gold-price-container {
        color: white;
        background-color: var(--color-primary);
        padding: 10px;
        width: auto;
        height: 400px;
        margin: auto;
    }

    .section-title {
        color: #ffffff;

        h4 {
            text-align: left;
            color: #ffffff;
            align-content: center;
            margin-left: 10px;
            margin-top: 10px;
        }

        p {
            font-size: 16px;
            font-weight: 400;
            text-align: right;
            color: #ffffff;
            line-height: 0 !important;
            margin-top: 25px;
        }

        .logo {
            width: 80px;
        }

        @media #{$lg-layout} {
            .logo-title {
                place-content: center;
                margin-bottom: 10px;
            }

            p {
                text-align: center;
                margin-top: 50px;
                margin-bottom: -20px;
            }
        }

        @media #{$md-layout} {
            .logo-title {
                margin-bottom: 10px;
            }

            p {
                margin-bottom: 10px;
            }
        }

        @media #{$sm-layout} {
            .logo-title {
                place-content: center;
                margin-bottom: 10px;
            }

            h4 {
                text-align: center;
                color: #ffffff;
            }

            p {
                font-size: 16px;
                font-weight: 400;
                text-align: center;
                color: #ffffff;
                margin-bottom: 15px;
            }

            .logo {
                text-align: center;
            }
        }

    }

    table {
        .title-product {
            font-size: 36px;
            margin-bottom: 0;

            @media (max-width: 575px) {
                font-size: 28px !important;
            }
        }

        .text-color-buy {
            color: red;
            text-align: center;

            @media (max-width: 575px) {
                font-size: 14px !important;
            }
        }

        .text-color-sell {
            color: green;
            text-align: center;

            @media (max-width: 575px) {
                font-size: 14px !important;
            }
        }

        .text-color-diff {
            color: #4ac9ff;
            text-align: center;

            @media (max-width: 575px) {
                font-size: 14px !important;
            }
        }

        .price {
            font-size: 32px;
            color: var(--color-primary);

            @media (max-width: 575px) {
                font-size: 22px !important;
            }
        }
    }


    .price-table {
        svg {
            font-size: 30px;

            @media (max-width: 575px) {
                font-size: 26px;
            }

            
        }

        h1{
            @media (max-width: 575px) {
                font-size: 26px !important;
            }
        }
    }



    .gold-price-table {
        width: 100%;
        margin-top: 20px;
        border-bottom: 0;

        .list {
            margin-top: 20px;
            margin-bottom: 20px;
        }



        .text-color-type {
            text-align: center;
        }



        .text-price {
            font-size: 36px;
            text-align: center;
            background-color: rgba(255, 255, 255, .0901960784);
        }

        @media #{$lg-layout} {
            th {
                font-size: 12px;
            }

            td {
                font-size: 12px;
            }

            .text-price {
                font-size: 26px;
            }
        }

        @media #{$md-layout} {
            th {
                font-size: 16px;
            }

            td {
                font-size: 16px;
            }
        }

        @media #{$sm-layout} {
            th {
                font-size: 12px;
            }

            td {
                font-size: 12px;
            }

            .text-price {
                font-size: 20px;
            }
        }
    }

    .gold-price-table th,
    .gold-price-table td {
        border-top: 0;
        padding: 5px;
        text-align: left;
    }

    .gold-price-table th {
        background-color: #111111;
    }

    .change {
        color: red;
    }

    time {
        font-style: italic;
    }

    .tradingview-widget-container {
        width: auto !important;
        height: 400px !important;
    }




    table {
        width: 100%;
        border-collapse: collapse;
        margin: 20px 0;
        background-color: #fff;

        .date {
            background-color: transparent;
            color: #000000;
        }
    }

    th,
    td {
        border: 1px solid #ddd;
        padding: 10px 20px;
    }

    th {
        color: var(--color-primary);
        font-size: 18px;
        font-weight: 400;
        text-align: center;



    }

    .logo {
        // width: 100px;
        max-width: 20%;
        padding: 10px 0;

        @media #{$sm-layout} {
            max-width: 80px;
        }
    }

    td {
        font-size: 14px;
        text-align: center;

        &.price {
            font-size: 32px;
            color: var(--color-primary);
        }

        &.name {
            font-size: 20px;
            color: #333333;
        }
    }

    thead th {
        position: sticky;
        top: 0;
    }

    caption {
        font-size: 20px;
        font-weight: bold;
        padding: 10px;
        // background-color: var(--color-primary);
        color: white;
    }

    .highlight {
        background-color: #f2f2f2;
    }



}