.product-menu {
    margin: 20px auto;

    .section-title {
        h1 {
            color: #333;
            font-size: var(--font-size-h1);
            font-weight: var(--font-weight-regular);
            text-align: center;
            margin-bottom: 16px;
        }
    }

    .testt {


        .grid-container {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 20px;
        }

        .card {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            transition: box-shadow 0.3s ease;
            height: 500px;

            // &:hover {
            //     .card img {
            //         transform: scale(1.03);
            //     }
            // }

            // img {
            //     width: 100%;
            //     height: auto;

            //     &:hover {
            //         transform: scale(1.03);
            //     }
            // }

            h3 {
                margin: 0 0 10px;
                font-size: 1.2rem;
            }

            p {
                margin: 0 0 10px;
                color: #555;
            }

            .img {
                height: 100%;
                background-size: cover;

                &:hover {
                    transform: scale(1.03);
                }
            }

            &::before {
                background: linear-gradient(to top, rgba(15, 15, 15, 0.50), rgba(15, 15, 15, 0), rgba(15, 15, 15, 0.90) 100%);
            }


        }

        .card-content {
            padding: 16px;
            flex: 1;
        }

        .product_btn {
            position: absolute;
            bottom: 0;
            padding: 20px;
        }
    }

    .im_product-menu {
        position: relative;
        z-index: 2;
        overflow: hidden;
        //border-radius: 10px;
        transition: transform .65s cubic-bezier(.05, .2, .1, 1), box-shadow .65s cubic-bezier(.05, .2, .1, 1);

        &::before {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            z-index: 1;
            content: '';
            background: linear-gradient(to top, rgba(15, 15, 15, 0.50), rgba(15, 15, 15, 0), rgba(15, 15, 15, 0.90) 100%);
            // background: linear-gradient(to bottom, rgba(15, 15, 15, 0), rgba(15, 15, 15, .75) 100%);
            transition: opacity .65s cubic-bezier(.05, .2, .1, 1);
            cursor: pointer;
        }

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 85%;
            display: block;
            z-index: 1;
            content: '';
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0), var(--color-primary) 100%);
            transition: opacity .65s cubic-bezier(.05, .2, .1, 1);
            cursor: pointer;
            opacity: 0;
        }

        // &:hover {
        //     transform: scale(1.03);
        // }

        .thumbnail_inner {
            .thumbnail {
                height: 100%;

                a {
                    display: block;

                    img {
                        transition: transform 1s ease, opacity 0.5s ease 0.25s;
                        //border-radius: 10px;
                        // width: 100%;
                        // height: 500px;
                    }
                }
            }
        }

        .content {
            position: absolute;
            top: 10px;
            width: 100%;
            height: 100%;

            .inner {
                position: absolute;
                // top: 0;
                // left: 0;
                // top: 70px;
                // padding: 30px 10px;
                z-index: 2;
                width: 100%;
                height: 100%;

                .product_heading {
                    display: block;
                    flex: auto;
                    // top: -400px;
                    // position: absolute;
                    padding: 20px;
                    // transform: translateY(-80px);
                    transition: opacity .65s cubic-bezier(.05, .2, .1, 1), transform .65s cubic-bezier(.05, .2, .1, 1);

                    .title {
                        color: #ffffff;
                        font-size: var(--font-size-h2);
                        font-weight: var(--font-weight-regular);
                        text-align: left;

                        a {
                            color: #ffffff;
                        }

                        @media #{$lg-layout} {
                            font-size: 24px;
                        }

                        @media #{$md-layout} {
                            font-size: 22px;
                        }

                        @media #{$sm-layout} {
                            font-size: 42px;
                        }

                        @media (max-width: 575px) {
                            font-size: 32px;
                            margin-bottom: 0;
                        }
                    }

                    .description {
                        color: #ffffff;
                        font-size: var(--font-size-h4);
                        font-weight: var(--font-weight-regular);
                        text-align: left;
                        margin-top: -10px;

                        a {
                            color: #ffffff;
                        }

                        @media #{$lg-layout} {
                            font-size: 18px;
                            margin-bottom: 0;
                        }

                        @media #{$md-layout} {
                            font-size: 16px;
                            margin-bottom: 0;
                        }

                        @media #{$sm-layout} {
                            font-size: 26px;
                            margin-bottom: 0;
                        }

                        @media (max-width: 575px) {
                            font-size: 26px;
                            margin-top: 0;
                        }
                    }
                }

                .product_btn {
                    // bottom: 270px;
                    padding: 20px;
                    position: absolute;
                    bottom: 10px;
                    width: 100%;
                    z-index: 99;

                    a {
                        width: 100%;
                    }

                }
            }
        }

        &:hover {
            .thumbnail_inner {
                .thumbnail {
                    a {
                        img {
                            transform: scale(1.05);
                            transition: all 9s cubic-bezier(.1, .2, 7, 1);
                        }
                    }
                }
            }
        }
    }
}

.block {
    padding: 10px;
}

.video-highlight {
    .section-title {
        h1 {
            color: #333;
            font-size: var(--font-size-h1);
            font-weight: var(--font-weight-regular);
            text-align: center;
            margin-bottom: 16px;
        }
    }
    max-width: 100%;

    @media (max-width: 575px) {
        margin-top: 0;
    }

    .image {
        margin-left: -1px;
        width: 20%;

        // @media #{$lg-layout} {
        //     height: 320px;
        // }

        // @media #{$md-layout} {
        //     height: 230px;
        // }

        // @media #{$sm-layout} {
        //     height: auto;
        // }

        @media (max-width: 575px) {
            width: 100%;
            margin-top: -10px;
        }
    }
}


.sale-bg {
    background-image: url(/assets/images/bg/bg-image-13.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom right;

    @media #{$sm-layout} {
        background-position: right;
        background-position-x: 80%;
        // right: 10%;
        text-align: center;
    }

    h1,
    p {
        color: #fff;
    }
}