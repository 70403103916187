.product-details-area {


  .product-details {
    display: flex;
    justify-content: space-between;
    padding: 20px;

    @media #{$md-layout} {
      display: contents;
    }

    @media #{$sm-layout} {
      display: contents;
    }
  }

  .product-image-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .product-info {
    flex: 1;
    padding-left: 20px;
  }

  .main-image {
    width: 80%;
    height: auto;
    margin-bottom: 20px;
  }

  .nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 18px;
    z-index: 1;
  }

  .nav-button.left {
    left: 10px;
  }

  .nav-button.right {
    right: 10px;
  }

  .additional-images {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .thumbnail {
    width: 80px;
    height: auto;
    cursor: pointer;
    border: 1px solid #ddd;
    padding: 5px;
    transition: transform 0.2s;
  }

  .thumbnail:hover {
    transform: scale(1.1);
  }

  .thumbnail.active {
    border: 2px solid #007BFF;
  }

  .back-link {
    display: block;
    margin-bottom: 10px;
    color: #007BFF;
    text-decoration: none;
  }

  .back-link:hover {
    text-decoration: underline;
  }

  h2 {
    margin: 20px 0;
  }

  .product-description,
  .product-details {
    margin-bottom: 20px;
  }

  .chat-shop-button,
  .size-button {
    display: block;
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    background-color: #00B900;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }

  .chat-shop-button:hover,
  .size-button:hover {
    background-color: #0056b3;
  }

  .product-share {
    margin: 20px 0;
  }

  .share-icons a {
    margin-right: 10px;
    color: #555;
    text-decoration: none;
  }

  .share-icons a:hover {
    color: #000;
  }

  .product-terms {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 4px;
  }

  .product-terms h3 {
    margin-top: 0;
  }

  .product-terms p {
    margin: 10px 0;
  }

}