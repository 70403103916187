/*====================
    About Area 
======================*/
.gold-saver-area {

    .gold-saver-bg {
        background-image: url(/assets/images/bg/bg-image-11.webp);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        height: 600px;
    }

    .section-1 {
        // background: #960000;
        margin-top: -200px;


        .section-title {
            .row {
                justify-content: center;
            }

            h4 {
                &.description {
                    font-size: 26px;
                    color: var(--color-black) !important;
                }
            }

            h3 {
                &.title {
                    color: var(--color-black);
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 80px;
                }

                &.title-color {
                    color: var(--color-primary);
                    font-size: var(--font-size-h1);
                    font-weight: var(--font-weight-bold);
                    margin-right: 10px;

                }
            }

            p {
                &.description {
                    // line-height: 15px;
                    font-size: var(--font-size-md);
                    color: var(--color-black) !important;
                }

                &.description-list {
                    // line-height: 10px;
                    margin-top: -20px;
                    text-align: left;
                    font-size: var(--font-size-md);
                    color: var(--color-secondary) !important;

                    .icon {
                        width: 30px;
                        margin-right: 10px;
                    }
                }

                &.description-color {
                    line-height: 26px;
                    color: var(--color-secondary);
                    font-size: 18px;
                    font-weight: 600;
                    margin-left: 10px;
                    margin-right: 10px;

                    @media #{$sm-layout} {
                        line-height: 10px;
                        margin-top: -30px;
                        padding-bottom: 20px;
                    }
                }
            }
        }

        .button {
            background-color: #f4f5f5;
            /* Green */
            border: none;
            width: 100%;
            color: var(--color-primary);
            padding: 10px 32px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 14px;
            font-weight: 00;
            cursor: pointer;
        }

        .button:hover {
            background-color: #dadada;
        }

        .gold-saver-top {
            padding: 30px 300px;
            margin-top: 100px;
            background-color: #ffffff;
            box-shadow: 0 4px 25px 0 rgba(96, 96, 96, 0.2);

            @media #{$lg-layout} {
                padding: 30px 150px;
            }

            @media #{$md-layout} {
                padding: 30px 50px;
            }

            @media #{$sm-layout} {
                padding: 30px 40px;
            }
        }
    }

    .section-2 {
        .section-title {
            .row {
                justify-content: center;
            }

            h4 {
                line-height: 10px;
            }

            h3 {
                &.title {
                    color: var(--color-white);
                    font-size: 20px;
                    font-weight: 400;
                }

                &.title-color {
                    color: var(--color-primary);
                    font-size: 46px;

                }
            }

            p {
                &.description {
                    font-size: 20px;
                    color: rgb(44, 44, 44);
                }

                &.description-color {
                    color: rgb(4, 253, 0);
                    font-size: 26px;
                    font-weight: 600;
                }
            }
        }
    }

    .section-3 {
        background-image: url(/assets/images/bg/bg-image-12.webp);
        background-repeat: no-repeat;
        background-size: cover;

        .section-title {
            .row {
                justify-content: center;
            }

            h4 {
                line-height: 10px;
            }

            p {
                &.description {
                    font-size: 20px;
                    color: rgb(44, 44, 44);
                }

                &.description-color {
                    color: rgb(4, 253, 0);
                    font-size: 26px;
                    font-weight: 600;
                }
            }
        }
    }

    .section-4 {
        .section-title {
            .row {
                justify-content: center;
            }

            h4 {
                &.description {
                    font-size: 26px;
                    color: var(--color-white) !important;
                }
            }

            h3 {
                &.title {
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 34px;
                }

                &.title-color {
                    color: var(--color-primary);
                    font-size: 32px;
                    margin-right: 10px;
                    margin-left: 10px;

                }
            }

            p {
                &.description {
                    line-height: 15px;
                    font-size: 20px;
                    color: rgb(0, 0, 0);
                }

                &.description-color {
                    line-height: 15px;
                    color: var(--color-primary);
                    font-size: 26px;
                    font-weight: 600;
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
        }


    }



    .des-area {
        background-image: url(/assets/images/bg/bg-image-9.webp);
        background-repeat: no-repeat;
        background-size: cover;

        .section-title {
            h2 {
                &.title {
                    color: var(--color-white);
                }
            }

            p {
                &.description {
                    font-size: 20px;
                    color: var(--color-white) !important;
                }
            }
        }
    }

    .bottom-area {
        background-image: url(/assets/images/bg/bg-image-10.webp);
        background-repeat: no-repeat;
        background-size: cover;

        .section-title {
            h2 {
                &.title {
                    color: var(--color-primary);
                }
            }

            p {
                &.description {
                    font-size: 20px;
                    color: var(--color-black) !important;
                }
            }
        }
    }

    .rn-finding-us-area {
        .container {
            .row {
                justify-content: center;
            }
        }

        .inner {
            position: relative;

            @media #{$lg-layout} {
                padding-left: 0;
                padding-top: 0;
                padding-bottom: 0;
                display: flex;
                flex-direction: column-reverse;
            }

            @media #{$md-layout} {
                padding-left: 0;
                padding-top: 0;
                padding-bottom: 0;
                display: flex;
                flex-direction: column-reverse;
            }

            @media #{$sm-layout} {
                padding-left: 0;
                padding-top: 0;
                padding-bottom: 0;
                display: flex;
                flex-direction: column-reverse;
            }

            .content-wrapper {
                .content {
                    padding: 80px 0px;
                    position: relative;
                    z-index: 2;
                    width: 800px;
                    //border-radius: 10px;
                    text-align: center;

                    @media #{$lg-layout} {
                        padding: 60px 50px;
                        width: 100%;
                        //border-radius: 0;
                    }

                    @media #{$md-layout} {
                        padding: 60px 50px;
                        width: 100%;
                        //border-radius: 0;
                    }

                    @media #{$sm-layout} {
                        padding: 30px 30px;
                        width: 100%;
                        //border-radius: 0;
                    }

                    h4 {
                        color: #ffffff;
                        font-size: 36px;
                        font-weight: 500;
                        margin-bottom: 20px;

                        @media #{$sm-layout} {
                            font-size: 26px;
                            margin-bottom: 12px;
                        }
                    }

                    p {
                        font-size: 18px;
                        color: #ffffff;
                        font-weight: 300;
                        margin-bottom: 41px;

                        @media #{$sm-layout} {
                            margin-bottom: 24px;
                            padding-right: 0;
                        }
                    }
                }
            }

            .thumbnail {
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;

                @media #{$lg-layout} {
                    position: static;
                    height: 100%;
                }

                @media #{$md-layout} {
                    position: static;
                    height: 100%;
                }

                @media #{$sm-layout} {
                    position: static;
                    height: 100%;
                }

                img {
                    //border-radius: 10px;

                    @media #{$lg-layout} {
                        //border-radius: 0;
                    }

                    @media #{$md-layout} {
                        //border-radius: 0;
                    }

                    @media #{$sm-layout} {
                        //border-radius: 0;
                    }
                }
            }
        }
    }

    .about-inner {
        @media #{$md-layout} {
            padding-top: 40px;
        }

        @media #{$sm-layout} {
            padding-top: 40px;
        }

        .icon {
            display: inline-block;
            width: 60px;
            height: 60px;
            background: var(--color-primary);
            //border-radius: 100%;
            text-align: center;
            color: #fff;
            line-height: 58px;
            font-size: 27px;
            margin-bottom: 17px;
            padding-right: 3px;


            svg {
                stroke-width: 1;
            }
        }

        .section-title {
            h2 {
                &.title {
                    line-height: 1.3;
                    margin-bottom: 35px;
                }
            }

            p {
                &.description {
                    font-weight: 300;
                    color: var(--color-gray);

                    a {
                        color: rgba(29, 29, 36, 1);

                        &:hover {
                            color: var(--color-primary);
                        }
                    }
                }
            }

        }
    }


    .about-wrapper {
        .thumbnail {
            position: relative;
            z-index: 2;

            img {
                //border-radius: 8px;
                box-shadow: none;

            }
        }
    }
}