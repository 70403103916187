/*====================
    About Area 
======================*/

.about-area {
    background-repeat: no-repeat;
    background-size: cover;

    .left-column, .right-column {
        flex: 1;
        padding: 20px;
        @media (max-width: 575px) {
            flex:content;
        }
    }
    
    .section-title {
        font-size: 40px;
        color: #000;
    }

    .main-title {
        font-size: 30px;
        color: #000;
    }
    
    .tagline {
        font-size: 20px;
        color: #313131;
        margin-bottom: 0;
    }
    
    .description {
        line-height: 1.6;
        margin-bottom: 20px;
        text-align: justify;
    }
    
    .featured-image {
        width: 100%;
        height: auto;
        margin-top: 20px;
    }
}

.bottom-area {
    background-image: url(/assets/images/bg/bg-image-10.webp);
    background-repeat: no-repeat;
    background-size: cover;

    .section-title {
        h2 {
            &.title {
                color: var(--color-primary);
            }
        }

        p {
            &.description {
                font-size: 20px;
                color: var(--color-black) !important;
            }
        }
    }
}

.rn-finding-us-area {
    .container {
        .row {
            justify-content: center;
        }
    }

    .inner {
        position: relative;

        @media #{$lg-layout} {
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 0;
            display: flex;
            flex-direction: column-reverse;
        }

        @media #{$md-layout} {
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 0;
            display: flex;
            flex-direction: column-reverse;
        }

        @media #{$sm-layout} {
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 0;
            display: flex;
            flex-direction: column-reverse;
        }

        .content-wrapper {
            .content {
                padding-top: 80px;
                padding-bottom: 0px;
                position: relative;
                z-index: 2;
                width: 1230px;
                text-align: center;

                @media #{$lg-layout} {
                    padding: 60px 50px;
                    width: 100%;
                }

                @media #{$md-layout} {
                    padding: 60px 50px;
                    width: 100%;
                }

                @media #{$sm-layout} {
                    padding: 30px 30px;
                    width: 100%;
                }

                h1{
                    color: #ffffff;
                    font-size: 24px;
                    font-weight: 500;
                }
                h4 {
                    color: #ffffff;
                    font-size: 36px;
                    font-weight: 500;
                    margin-bottom: 20px;

                    @media #{$sm-layout} {
                        font-size: 26px;
                        margin-bottom: 12px;
                    }
                }

                p {
                    font-size: 18px;
                    color: #ffffff;
                    font-weight: 300;
                    margin-bottom: 41px;

                    @media #{$sm-layout} {
                        margin-bottom: 24px;
                        padding-right: 0;
                    }
                }
            }
        }

        .thumbnail {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;

            @media #{$lg-layout} {
                position: static;
                height: 100%;
            }

            @media #{$md-layout} {
                position: static;
                height: 100%;
            }

            @media #{$sm-layout} {
                position: static;
                height: 100%;
            }

            img {

                @media #{$lg-layout} {
                }

                @media #{$md-layout} {
                }

                @media #{$sm-layout} {
                }
            }
        }
    }
}

.about-inner {
    @media #{$md-layout} {
        padding-top: 40px;
    }

    @media #{$sm-layout} {
        padding-top: 40px;
    }

    .icon {
        display: inline-block;
        width: 60px;
        height: 60px;
        background: var(--color-primary);
        //border-radius: 100%;
        text-align: center;
        color: #fff;
        line-height: 58px;
        font-size: 27px;
        margin-bottom: 17px;
        padding-right: 3px;


        svg {
            stroke-width: 1;
        }
    }

    .section-title {
        h2 {
            &.title {
                line-height: 1.3;
                margin-bottom: 35px;
            }
        }

        p {
            &.description {
                font-weight: 300;
                color: var(--color-gray);

                a {
                    color: rgba(29, 29, 36, 1);

                    &:hover {
                        color: var(--color-primary);
                    }
                }
            }
        }

    }
}


.about-wrapper {
    .thumbnail {
        position: relative;
        z-index: 2;

        img {
            box-shadow: none;

        }

        
    }

    .image-highlight{
        img{
            box-shadow: 10px 10px 20px #00000057;

            @media #{$lg-layout} {
            }
        
            @media #{$md-layout} {
                margin-top: 60px !important;
                margin-bottom: 0;
            }
        
            @media #{$sm-layout} {
                margin-top: 60px !important;
                margin-bottom: 0;
            }
        }
    }
}