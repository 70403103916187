/*====================
    Product Area 
======================*/

.transparent_link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.section-title {
    h1.title-madeorder {
        color: #333;
        font-size: var(--font-size-h1);
        font-weight: var(--font-weight-regular);
        text-align: center;
        margin-bottom: 16px;
    }

    p.description-madeorder{
        margin-top: -10px;
        text-align: center;
        
    }
}

.product-area {
    .product-breadcrumb {
        .product-breadcrumb-inner {
            // background-color: #fff;
            background: linear-gradient(to right, #BF953F, #FCF6BA, #e4b755, #FBF5B7, #AA771C);
            margin-top: 250px;
            // border: 2px solid white;
            margin-bottom: -180px;
            text-align: center;
            padding: 25px 150px;
            padding-top: 30px;
            padding-bottom: 25px;
            // color: black;
            opacity: 0.95;
            box-shadow: 0 15px 30px 0 rgba(77, 77, 77, 0.14);

            @media #{$lg-layout} {
                padding: 25px 100px;
            }

            @media #{$md-layout} {
                padding: 25px 50px;
            }

            @media #{$sm-layout} {
                padding: 25px 50px;
                // margin-top: 50px;

            }

            @media (max-width: 575px) {
                // margin-top: 200px;
                // margin-bottom: 100px !important;
            }

            p {
                font-size: var(--font-size-md);
                margin-bottom: 0;
                // color: var(--color-primary);
                color: black;

                @media #{$lg-layout} {
                    font-size: 16px !important;
                }

                @media #{$md-layout} {
                    font-size: 16px !important;
                }

                @media #{$sm-layout} {
                    font-size: 16px !important;
                }

                @media (max-width: 575px) {
                    font-size: 16px !important;
                }
            }

            h2 {
                &.title {
                    color: var(--color-primary);
                    color: #fff;
                    margin-bottom: 5px;
                    font-size: var(--font-size-h1);
                    font-weight: 500;

                    @media #{$lg-layout} {
                        font-size: 30px;
                    }

                    @media #{$md-layout} {
                        font-size: 26px;
                    }

                    @media #{$sm-layout} {
                        font-size: 26px;
                    }

                    @media (max-width: 575px) {
                        font-size: 20px;
                    }
                }
            }

            ul {
                &.page-list {
                    @extend %liststyle;

                    li {
                        display: inline-block;
                        color: var(--color-primary);
                        padding: 0 12px;
                        position: relative;
                        font-size: 14px;

                        &::after {
                            position: absolute;
                            content: "";
                            top: 50%;
                            transform: translateY(-50%);
                            background-color: #fff;
                            width: 5px;
                            height: 5px;
                            //border-radius: 100%;
                            opacity: 0.5;
                            right: -3px;
                        }

                        a {
                            color: var(--color-black);
                            transition: 0.5s;

                            &:hover {
                                color: var(--color-secondary);
                            }
                        }

                        &.rn-breadcrumb-item {
                            &.active {
                                color: var(--color-primary);

                                &::after {
                                    display: none;
                                }
                            }
                        }

                    }
                }
            }
        }
    }









    .product-top {
        background-repeat: no-repeat;
        background-size: cover;

        .section-title {
            h3 {
                font-weight: 600;
                color: var(--color-white);
            }

            p {
                color: rgb(225, 225, 225);
            }
        }
    }

    .product-des {
        // padding-top: 10px;
        background: url(/assets/images/bg/bg-image-12.webp);
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;

        @media #{$sm-layout} {
            margin-top: 300px;
        }

        @media (max-width: 575px) {
            margin-top: 300px;
        }

        @media (max-width: 485px) {
            margin-top: 400px;
        }

        @media (max-width: 400px) {
            margin-top: 500px;
        }

        @media (max-width:375px) {
            margin-top: 550px;
        }

        @media (max-width: 345px) {
            margin-top: 600px;
        }

        @media (max-width: 335px) {
            margin-top: 650px;
        }

        @media (max-width: 315px) {
            margin-top: 700px;
        }

        @media (max-width: 305px) {
            margin-top: 800px;
        }

        .section-3 {
            background-image: url(/assets/images/bg/bg-image-8.webp);
            background-attachment: fixed;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .section-1 {

            .row {
                justify-content: center;
                margin: 0;
            }

            .section-title {

                text-align: center;

                .main-title {
                    font-size: var(--font-size-h1);
                    font-weight: var(--font-weight-regular);
                    text-align: left;
                    margin-top: 15px;

                    @media #{$sm-layout} {
                        font-size: 26px;

                    }
                }

                .description {
                    font-weight: var(--font-weight-regular);
                    color: #313131;
                    margin-bottom: 0;
                    text-align: left;
                    margin-top: 15px;
                }

                .tagline {
                    // font-size: var(--font-size-md);
                    font-weight: var(--font-weight-regular);
                    color: #313131;
                    margin-bottom: 0;
                    text-align: left;
                    margin-top: 15px;



                }

                h2.tagline {
                    font-size: 22px;
                    font-weight: var(--font-weight-bold);

                    @media #{$sm-layout} {
                        font-size: 18px;
                        text-align: center;

                    }
                }

                p.tagline {
                    font-size: var(--font-size-md);
                    font-weight: var(--font-weight-regular);
                    margin-top: 0;

                    @media #{$sm-layout} {
                        text-align: center;

                    }
                }



                img {
                    width: 150px;
                }

            }

        }



        .section-title {
            h4 {
                color: black;
            }

            p {
                color: #383838;
            }
        }

        .section-title-2 {
            h4 {
                color: rgb(255, 255, 255);
            }

            p {
                color: #eaeaea;
            }
        }

        .section-title-3 {
            h4 {
                color: rgb(255, 255, 255);
            }

            p {
                color: #eaeaea;
                margin-left: 100px;

                @media #{$lg-layout} {
                    margin-left: 60px;
                }

                @media #{$md-layout} {
                    margin-left: 20px;
                }

                @media #{$sm-layout} {
                    margin-left: 0px;
                }
            }
        }


        .content {
            .row {
                place-content: center;

                h4,
                p {
                    color: #383838;
                }

                p {
                    padding-left: 30px;
                    align-self: center;

                    @media #{$lg-layout} {
                        margin-top: 20px;
                        margin-bottom: 20px;
                    }

                    @media #{$md-layout} {
                        margin-top: 20px;
                        margin-bottom: 20px;
                    }

                    @media #{$sm-layout} {
                        margin-top: 20px;
                        margin-bottom: 20px;
                    }
                }


            }
        }





    }

    .item-portfolio-static {
        margin-top: 20px;
        margin-bottom: 20px;

        @media #{$sm-layout} {
            margin-top: 30px;
        }

        @media #{$md-layout} {
            margin-top: 30px;
        }

        .inner {
            padding-top: 20px;

            p {
                margin-bottom: 5px;
                font-size: 16px;
            }

            h4 {
                @media #{$sm-layout} {
                    font-size: 18px;
                }

                a {
                    color: $heading-color;
                    transition: 0.3s;

                    &:hover {
                        color: var(--color-primary);
                    }
                }
            }
        }

        .thumbnail {
            a {
                img {
                    @extend %transition;
                }
            }
        }

        &:hover {
            .thumbnail {
                a {
                    img {
                        transform: translate3d(0, -10px, 0);
                        box-shadow: 0 50px 80px -10px rgba(0, 0, 0, 0.17);
                    }
                }
            }
        }

    }

    .menubar {
        table {
            width: inherit;

            tr {
                border: 1px solid rgb(217, 217, 217);

                a {
                    padding: 20px;
                }

                &:hover {
                    background: var(--color-primary);

                    a {
                        color: #ffffff;
                        transition: 0ms;

                    }

                }

                .active {
                    background: var(--color-primary);
                    color: #ffffff;
                }
            }
        }

        .btn-default {
            width: 100%;
            background: transparent;
            color: var(--color-black);
            transition: 0ms;
            border: 0;
        }

        .col-3 {
            max-width: 100%;
        }
    }

    .section-title {
        h3 {
            font-size: 34px;
            font-weight: 600;
        }

        p {
            color: rgb(77, 77, 77);
        }

    }

    .item-img-static {
        margin-top: 60px;

        @media #{$sm-layout} {
            margin-top: 30px;
        }

        @media #{$md-layout} {
            margin-top: 30px;
        }

        .thumbnail {
            @extend %transition;
        }

        &:hover {
            .thumbnail {
                transform: scale(1.05);
                transition: transform 0.4s ease-in-out;
                box-shadow: 0 0 25px 0px rgba(0, 0, 0, 0.12)
            }
        }

    }


    .product-menu-wrapper {

        
        .section-title {
            h1 {
                color: #333;
                font-size: var(--font-size-h1);
                font-weight: var(--font-weight-regular);
                text-align: center;
                margin-bottom: 16px;
            }
        }

        .row {
            justify-content: center;
        }

        .block {
            padding: 10px;
        }

        .im_product-menu {
            position: relative;
            z-index: 2;
            overflow: hidden;
            //border-radius: 10px;
            transition: transform .65s cubic-bezier(.05, .2, .1, 1), box-shadow .65s cubic-bezier(.05, .2, .1, 1);

            &::before {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 85%;
                display: block;
                z-index: 1;
                content: '';
                background: linear-gradient(to bottom, rgba(15, 15, 15, 0), rgba(15, 15, 15, .75) 100%);
                transition: opacity .65s cubic-bezier(.05, .2, .1, 1);
                cursor: pointer;
            }

            &::after {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 85%;
                display: block;
                z-index: 1;
                content: '';
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0), var(--color-primary) 100%);
                transition: opacity .65s cubic-bezier(.05, .2, .1, 1);
                cursor: pointer;
                opacity: 0;
            }

            .thumbnail_inner {
                .thumbnail {
                    a {
                        display: block;

                        img {
                            transition: transform 1s ease, opacity 0.5s ease 0.25s;
                            //border-radius: 10px;
                            width: 100%;
                        }
                    }
                }
            }

            .content {
                .inner {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    padding: 30px 10px;
                    z-index: 2;
                    width: 100%;

                    @media #{$sm-layout} {
                        padding: 15px 10px;
                    }

                    .product_heading {
                        transform: translateY(-120px);
                        transition: opacity .65s cubic-bezier(.05, .2, .1, 1), transform .65s cubic-bezier(.05, .2, .1, 1);

                        @media #{$lg-layout} {
                        transform: translateY(-100px);
                        }

                        @media #{$md-layout} {
                        transform: translateY(-60px);
                        }

                        @media #{$sm-layout} {
                        transform: translateY(-220px);
                        }

                        @media (max-width: 575px) {
                            transform: translateY(-130px);
                        }

                        .title {
                            color: #ffffff;
                            font-size: 24px;
                            font-weight: 400;
                            text-align: center;

                            a {
                                color: #ffffff;
                            }

                            @media #{$lg-layout} {
                                font-size: 24px;
                                margin-bottom: 0;
                            }

                            @media #{$md-layout} {
                                font-size: 22px;
                                margin-bottom: 0;
                            }

                            @media #{$sm-layout} {
                                font-size: 32px;
                                margin-bottom: 0;
                            }

                            @media (max-width: 575px) {
                                font-size: 32px;
                                margin-bottom: 0;
                            }
                        }
                    }



                }
            }

            &:hover {
                box-shadow: 0 25px 55px rgba(253, 71, 102, 0.22);

                &::before {
                    opacity: 0;
                }

                &::after {
                    opacity: 1;
                }

                .thumbnail_inner {
                    .thumbnail {
                        a {
                            img {
                                transform: scale(1.1);
                                transition: all 9s cubic-bezier(.1, .2, 7, 1);
                            }
                        }
                    }
                }

                .content {
                    .inner {
                        .product_heading {
                            transform: translateY(-125px);
                        transition: opacity .65s cubic-bezier(.05, .2, .1, 1), transform .65s cubic-bezier(.05, .2, .1, 1);

                        @media #{$lg-layout} {
                        transform: translateY(-105px);
                        }

                        @media #{$md-layout} {
                        transform: translateY(-65px);
                        }

                        @media #{$sm-layout} {
                        transform: translateY(-225px);
                        }

                        @media (max-width: 575px) {
                            transform: translateY(-135px);
                        }
                        }

                        .product_hover {
                            transform: translateY(0);
                            opacity: 1;
                            transition: opacity .65s cubic-bezier(.05, .2, .1, 1) 0.15s, transform .65s cubic-bezier(.05, .2, .1, 1) 0.15s;
                        }

                    }
                }
            }
        }
    }


    .gold {
        .section-title {
            .title {
                font-size: 48px;
                font-weight: 500;
                margin: 0;
            }

            .description {
                margin-top: 10px;
                font-size: 16px;
                color: black;
            }
        }

        .dataTable {
            // width: 100%;
            border-collapse: collapse;
            font-size: 16px;
        }

        .dataTable th,
        .dataTable td {
            padding: 8px;
            text-align: left;
            color: black;

            width: 50%;
        }

        .dataTable th {
            background-color: #f4f4f4;
        }


        .image-row {
            display: flex;
            justify-content: center;
            gap: 20px;
        }

        .image-row img {
            width: 50%;
            height: auto;
        }
    }
}

.madeorder{
    margin-top: -40px;
}