.product-menu {
  padding: 20px;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.product {
  width: calc(20% - 20px); /* Adjust to ensure proper spacing */
  overflow: hidden;
  text-align: center;
  background-color: #fff;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
  position: relative;
  margin-top: 5px;
}

.product:hover {
  box-shadow: 0 4px 8px rgba(100, 100, 100, 0.2);
}

.image-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%; /* Maintain aspect ratio */
  overflow: hidden;
}

.product-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.4s ease-in-out;
}

.product-image.fade-in {
  opacity: 1;
  z-index: 1;
  transition: opacity 0.4s ease-in-out;

}

.product-image.fade-out {
  opacity: 0.7;
}

.product-info {
  padding: 10px;
}

.product-name {
  font-size: var(--font-size-h3);
  font-weight: var(--font-weight-regular);
  margin-top: 0px;
  color: #000000;
}

.product-description {
  font-size: var(--font-size-md);
  color: #666;
  margin-top: 0;
}
